// Desc: Utility functions
export function formatDate(dateString) {
  if (!dateString) {
    return "";
  }

  try {
    // Parse the UTC date string manually
    const [datePart, timePart] = dateString.split(" ");
    if (!datePart || !timePart) {
      throw new Error("Invalid date format");
    }

    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);

    // Create a Date object in UTC
    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    // Convert the UTC date to the local time string
    return utcDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Enables AM/PM format
    });
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}


export function formatDateWithoutTime(dateString) {
  // Create a new Date object from the API date string
  let date = new Date(dateString);

  // Convert the date to a string using the browser's time zone
  return date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
}