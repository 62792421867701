import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link, json } from "react-router-dom";
import {
  signIn,
  fetchUserRoles,
  getCurrentUser,
} from "../../services/cognito/cognitoAuth";
import {
  registerToken,
  requestNotificationPermission,
} from "../../services/firebase/firebaseConfig";
import { getApps, getApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import AuthContext from "../auth/AuthContext";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const { setAuthenticated } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const { result: authResult, cognitoUser: currentUser } = await signIn(
        username,
        password
      );
      window.currentUser = currentUser;
      if (authResult?.newPasswordRequired) {
        window.newPasswordRequired = true;
        navigate("/change-password");
      } else {
        const userRoles = await fetchUserRoles(currentUser);
        //setRoles(userRoles);

        const user = await getCurrentUser();
        setAuthenticated(true);

        // Register FCM token after successful login
        const app = getApps()[0] || getApp();
        const messaging = getMessaging(app);
        const fcmToken = await requestNotificationPermission();
        const cognitoSub = user.sub;
        if (fcmToken) {
          await registerToken(fcmToken, cognitoSub);
        }

        // Perform role-based redirection
        // if (userRoles.includes("SuperAdmin")) {
        //   navigate("/users");
        // }
        // else if (userRoles.includes("Developer")) {
        //   navigate("/import-documents");
        // }
        // else{
        //   navigate("/dashboard");
        // }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control rounded-0"
            id="floatingInput"
            placeholder="Username"
          />
          <label htmlFor="floatingInput">Username</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control rounded-0"
            id="floatingPassword"
            placeholder="Password"
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <div className="text-center my-4">
          Forgot your password?{" "}
          <Link to="/forgot-password" className="text-blue">
            Click Here
          </Link>
        </div>
        <button className="btn btn-primary w-100" type="submit">
          LOG IN
        </button>
        {error && <div className="text-danger text-center mt-2">{error}</div>}
      </form>
    </>
  );
}

export default Login;
