import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";

function AddTenant({ onModelCreated, tenant }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(tenant ? tenant.name : "");
  const [nameError, setNameError] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState(tenant ? tenant.models.map((model) => ({ value: model.id, label: model.name })) : []);
  
  const fetchModels = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const params = {
        page: 1,
        size: "",
        search: "",
        status: "ACTIVE",
        sortColumn: "id",
        sortOrder: "DESC",
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}model/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      setModels(response.data.content);
    } catch (error) {
      console.error("Error fetching model data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchModels();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (name === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!isValid) {
      return;
    }

    const token = await getCurrentToken();

    if (!token) {
      console.error("No token found");
      return;
    }

    const tenantData = {
      name,
      modelIds: selectedModels.map((model) => model.value),
    };
    if (tenant) {
        tenantData.id = tenant.id;
    }
    try {
      if (tenant) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}tenant`,
          tenantData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}tenant`,
          tenantData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      onModelCreated();
      // Reset form state
      setName("");
      setSelectedModels([]);
      setNameError("");
    } catch (error) {
      console.error("Error creating/updating Tenant:", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Tenant Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        {nameError && <div className="text-danger">{nameError}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="model" className="form-label">
          Models
        </label>
        <Select
          options={models.map((model) => ({
            value: model.id,
            label: model.name,
          }))}
          isMulti 
          value={selectedModels}
          onChange={(selectedOptions) => setSelectedModels(selectedOptions)}
        />
      </div>
      <div className="text-end">
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  );
}

export default AddTenant;
