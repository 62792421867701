import React, { useState, useEffect, useContext } from "react";
import { Search } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import ProjectsList from "../components/projects/ProjectsList";
import AddProject from "../components/projects/AddProject";
import EditProject from "../components/projects/EditProject";
import ProjectsListAdmin from "../components/projects/ProjectListAdmin";
import ProjectContext from "../ProjectContext";

function Projects() {
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [editProject, setEditProject] = useState(null);
  const { roles } = useContext(ProjectContext);
  const triggerRefresh = () => setRefreshKey(refreshKey + 1);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEditProject(null);
  };
  const handleEditProject = (project) => {
    setEditProject(project);
    handleShow();
  };
  return (
    <div className="container-fluid flex-grow-1">
      <div className="d-flex align-items-center justify-content-between py-3">
        <h2 className="fw-bold mb-0">Projects</h2>
        <div className="d-flex align-items-center">
          <div className="input-group flex-nowrap me-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Projects"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
          {roles.includes("TenantAdmin") && (
            <button
              className="btn btn-primary"
              onClick={handleShow}
              style={{ minWidth: "175px" }}
            >
              Add Project
            </button>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        {editProject ? (
          <EditProject
            project={editProject}
            handleClose={handleClose}
            triggerRefresh={triggerRefresh}
          />
        ) : (
          <AddProject
            handleClose={handleClose}
            triggerRefresh={triggerRefresh}
          />
        )}
      </Modal>
      <ProjectsListAdmin
          searchQuery={searchQuery}
          refreshKey={refreshKey}
          onEditProject={handleEditProject}
        />
    </div>
  );
}

export default Projects;
