import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoSM from "../../assets/images/logo-sm.png";
import ProjectContext from "../../ProjectContext";
import {
  GridFill,
  FileEarmarkTextFill,
  PeopleFill,
  FolderFill,
  List,
  FileEarmarkArrowDownFill,
  JournalText,
  Speedometer2,
  CardList,
  ListTask,
  Stack,
  PersonVcard,
  TagFill,
} from "react-bootstrap-icons";

function LeftMenu() {
  const location = useLocation();
  const [isMenuCondensed, setIsMenuCondensed] = useState(false);
  const { roles, setRoles } = useContext(ProjectContext);

  const toggleMenu = () => {
    setIsMenuCondensed(!isMenuCondensed);
  };
  return (
    <div
      className={`leftside-menu d-flex flex-column pt-4 ${
        isMenuCondensed ? "condensed" : ""
      }`}
    >
      <div className="logo-left d-flex align-items-center justify-content-between">
        <img src={logo} alt="logo" className="img-fluid logo me-2" />
        {/* <img src={logoSM} alt="logo" className="logo-sm" /> */}
        <button className="button-toggle-menu" onClick={toggleMenu}>
          <List />
        </button>
      </div>
      <ul className="side-nav list-unstyled">
        {/* {!(roles.includes("SuperAdmin") || roles.includes("ProjectAdmin")) ? (
          <li className="side-nav-item">
            <NavLink
              to="/home"
              className="side-nav-link"
              activeclassname="active"
            >
              <GridFill size={20} className="me-2" />
              <span>Home</span>
            </NavLink>
          </li>
        ) : (
          <li className="side-nav-item">
            <NavLink
              to="/dashboard"
              className="side-nav-link"
              activeclassname="active"
            >
              <Speedometer2 size={20} className="me-2" />
              <span>Dashboard</span>
            </NavLink>
          </li>
        )} */}
        {(roles.includes("ProjectAdmin") || roles.includes("Reviewer")) && (
          <li className="side-nav-item">
            <NavLink
              to="/dashboard"
              className="side-nav-link"
              activeclassname="active"
            >
              <Speedometer2 size={20} className="me-2" />
              <span>Dashboard</span>
            </NavLink>
          </li>
        )}
        {(roles.includes("Developer") || roles.includes("ProjectAdmin")) && (
          <li className="side-nav-item">
            <NavLink
              to="/import-documents"
              className="side-nav-link"
              activeclassname="active"
            >
              <FileEarmarkArrowDownFill size={20} className="me-2" />
              <span>Import Documents</span>
            </NavLink>
          </li>
        )}
        {(roles.includes("Developer") || roles.includes("ProjectAdmin")) && (
          <>
            <li className="side-nav-item">
              <NavLink
                to="/documents"
                className="side-nav-link"
                activeclassname="active"
              >
                <FileEarmarkTextFill size={20} className="me-2" />
                <span>Documents</span>
              </NavLink>
            </li>
          </>
        )}
        {roles.includes("Reviewer") && (
          <li className="side-nav-item">
            <NavLink
              to="/files"
              className={`side-nav-link ${
                ["/files", "/file-viewer", "/add-issue"].includes(
                  location.pathname
                )
                  ? "active"
                  : ""
              }`}
            >
              <FileEarmarkTextFill size={20} className="me-2" />
              <span>Documents</span>
            </NavLink>
          </li>
        )}
        {(roles.includes("SuperAdmin") ||
          roles.includes("ProjectAdmin") ||
          roles.includes("TenantAdmin")) && (
          <li className="side-nav-item">
            <NavLink
              to="/users"
              className="side-nav-link"
              activeclassname="active"
            >
              <PeopleFill size={20} className="me-2" />
              <span>Users</span>
            </NavLink>
          </li>
        )}
        {roles.includes("TenantAdmin") && (
          <li className="side-nav-item">
            <NavLink
              to="/projects"
              className="side-nav-link"
              activeclassname="active"
            >
              <FolderFill size={20} className="me-2" />
              <span>Projects</span>
            </NavLink>
          </li>
        )}
        {roles.includes("ProjectAdmin") && (
          <>
            <li className="side-nav-item">
              <NavLink
                to="/action-log"
                className="side-nav-link"
                activeclassname="active"
              >
                <JournalText size={20} className="me-2" />
                <span>Action Log</span>
              </NavLink>
            </li>
            <li className="side-nav-item">
              <NavLink
                to="/title-list"
                className="side-nav-link"
                activeclassname="active"
              >
                <CardList size={20} className="me-2" />
                <span>Titles</span>
              </NavLink>
            </li>
          </>
        )}
        {!(roles.includes("SuperAdmin") || roles.includes("TenantAdmin")) && (
          <li className="side-nav-item">
            <NavLink
              to="/issues"
              className="side-nav-link"
              activeclassname="active"
            >
              <ListTask size={20} className="me-2" />
              <span>Issues</span>
            </NavLink>
          </li>
        )}
        {(roles.includes("ProjectAdmin") || roles.includes("Reviewer")) && (
          <li className="side-nav-item">
            <NavLink
              to="/preset-tag"
              className="side-nav-link"
              activeclassname="active"
            >
              <TagFill size={20} className="me-2" />
              <span>Preset Tag</span>
            </NavLink>
          </li>
        )}
        {roles.includes("SuperAdmin") && (
          <>
            <li className="side-nav-item">
              <NavLink
                to="/model-list"
                className="side-nav-link"
                activeclassname="active"
              >
                <Stack size={20} className="me-2" />
                <span>Models</span>
              </NavLink>
            </li>
            <li className="side-nav-item">
              <NavLink
                to="/tenants-list"
                className="side-nav-link"
                activeclassname="active"
              >
                <PersonVcard size={20} className="me-2" />
                <span>Tenants</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default LeftMenu;
