import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken, getCurrentUser } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import Select from "react-select";

function SwitchProject() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { roles, userId, tenantId, projectId, setProjectId, projectName, setProjectName } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);
  
  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentUser();
      setCognitoSub(user.sub);
    };
    fetchUser();
  }, []);
  useEffect(() => {
    if (cognitoSub) {
      fetchProjects(cognitoSub);
    }
  }, [cognitoSub]);
  const fetchProjects = async (cognitoSub) => {
    setLoading(true);
    try {
      const token = await getCurrentToken();
  
      if (!token) {
        console.error("No token found");
        return;
      }
  
      const userResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      const projectsData = [];
  
      userResponse.data.userGroups
        .filter(group => group.tenantId === tenantId)
        .forEach(group => {
          projectsData.push({
            value: group.projectId,
            label: group.project,
          });
        });
  
      setProjects(projectsData);
  
      const currentProject = projectsData.find(project => project.value === projectId);
      setSelectedProject(currentProject);
  
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };
  // const fetchProjects = async () => {
  //   setLoading(true);
  //   try {
  //     const token = await getCurrentToken();

  //     if (!token) {
  //       console.error("No token found");
  //       return;
  //     }
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}project/list`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         params: {
  //           page: 1,
  //           size: "",
  //           search: "",
  //           status: "",
  //           sortColumn: "lastAccessedOn",
  //           sortOrder: "DESC",
  //           userId: userId,
  //           tenantId: tenantId,
  //         },
  //       }
  //     );
  //     const jsonData = response.data;
  //     if (Array.isArray(jsonData.content)) {
  //       const sortedProjects = jsonData.content.sort((a, b) => {
  //         if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
  //           return -1;
  //         }
  //         if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
  //           return 1;
  //         }
  //         return 0;
  //       });
  //       const projectsData = sortedProjects.map((project) => ({
  //         value: project.id,
  //         label: project.name,
  //       }));
  //       setProjects(projectsData);
  //       const currentProject = projectsData.find(
  //         (project) => project.value === projectId
  //       );
  //       setSelectedProject(currentProject);
  //       if (currentProject) {
  //         setProjectName(currentProject.label);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching model data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const switchProject = async (projectId, projectName) => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/last-accessed-on`,
        {
          projectId,
          userId,
          tenantId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      setProjectId(projectId);
      setProjectName(projectName);
      window.location.reload();
    } catch (err) {
      console.log("Error in switchProject:", err);
    }
  };
  const handleChange = (selectedOption) => {
    setSelectedProject(selectedOption);
    switchProject(selectedOption.value, selectedOption.label);
  };
  return (
    <div className="me-3 d-flex align-items-center">
      <label className="me-2 fw-bold fs-6">Switch Project</label>
      <Select
        options={projects}
        value={selectedProject}
        onChange={handleChange}
        isLoading={loading}
        className="custom-select"
        classNamePrefix="custom-select"
      />
    </div>
  );
}

export default SwitchProject;
