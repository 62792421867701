import React, { useEffect, useState, useContext } from "react";
import { getCurrentUser, signOut } from "../../services/cognito/cognitoAuth";
import { Dropdown } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AuthContext from "../auth/AuthContext";
import { deRegisterToken } from "../../services/firebase/firebaseConfig";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getApps, getApp } from "firebase/app";
import { List } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectContext from "../../ProjectContext";
import SwitchTenant from "../tenant/SwitchTenant";
import SwitchProject from "../projects/SwitchProject";

function Header() {
  const [user, setUser] = useState();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(AuthContext);
  const app = getApps()[0] || getApp();
  const messaging = getMessaging(app);
  const {
    setProjectId,
    setProjectName,
    isProcessing,
    setIsProcessing,
    setRefreshFiles,
    setCurrentPage,
    roles,
  } = useContext(ProjectContext);

  // Function to get the initial theme
  const getInitialTheme = () => {
    // Check if theme is saved in localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      return savedTheme;
    }

    // Check the device's preferred theme
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return prefersDark ? "dark" : "light";
  };

  const [theme, setTheme] = useState(getInitialTheme);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    document.body.className = theme + "-theme";
  }, [theme]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        setUser(user);
      } catch (err) {
        console.error(err);
        if (err.message === "Refresh Token has expired") {
          await signOut();
          setAuthenticated(false);
          navigate("/login");
        }
      }
    };

    fetchUser();

    // Listen for incoming FCM messages
    const unsubscribe = onMessage(messaging, (payload) => {
      // Process your message as desired
      setNotifications((oldNotifications) => [...oldNotifications, payload]);

      // Show a toast notification
      toast(JSON.stringify(payload.data.message));

      if (payload.data.message) {
        setIsProcessing(false);
        setRefreshFiles(true);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  const handleSignOut = async () => {
    // Check if Notification permission is granted
    if (Notification.permission === "granted") {
      // De-register FCM token during sign out
      const fcmToken = await getToken(messaging);
      if (fcmToken) {
        await deRegisterToken(fcmToken, user.sub);
      }
    }
    await signOut();
    navigate("/login");
    setUser(null);
    setAuthenticated(false);
    setProjectId(null);
    setProjectName(null);
    // Clear the items from the local storage
   localStorage.clear();
    setCurrentPage(1);
  };
  const handleChangePassword = () => {
    navigate("/change-password");
  };
  return (
    <>
      <ToastContainer />
      <header className="navbar navbar-expand-lg">
        <div className="container-fluid justify-content-end align-items-center">
          {(roles.includes("ProjectAdmin") || roles.includes("Reviewer")) && <SwitchProject />}
          {user && (
            <Dropdown className="profile-info">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="text-uppercase rounded-pill"
              >
                {user.username.charAt(0)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="px-3 pb-2">
                  Hi{" "}
                  <span className="fw-bold text-primary">{user.username}</span>
                  <div className="fs-sm fw-bold text-uppercase">{roles}</div>
                  {!(roles.includes("SuperAdmin") || roles.includes("Developer")) && <SwitchTenant />}
                </div>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleChangePassword}>
                  Change Password
                </Dropdown.Item>
                {/* <Dropdown.Item href="#">Turn on MFA</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
                <Dropdown.Divider />

                <div className="toggle-container">
                  <label>Light</label>
                  <input
                    type="checkbox"
                    id="theme-toggle"
                    className="theme-toggle"
                    checked={theme === "dark"}
                    onChange={toggleTheme}
                  />
                  <label
                    htmlFor="theme-toggle"
                    className="toggle-label"
                  ></label>
                  <label>Dark</label>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;
