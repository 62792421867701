import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import AWS from "aws-sdk";
import { cognitoConfig } from "../../services/cognito/config";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Underline from "../../utils/Underline";

const EditorComponent = forwardRef(
  ({ initialContent, onContentChange }, ref) => {
    const [editorReady, setEditorReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const debounce = (fn, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
      };
    };

    const debouncedOnUpdate = useCallback(
      debounce((html) => {
        if (onContentChange) {
          onContentChange(html);
        }
      }, 300),
      [onContentChange]
    );
    

    const editor = useEditor({
      extensions: [StarterKit, Image, Link, Underline],
      content: initialContent,
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        debouncedOnUpdate(html);
      },

      editorProps: {
        handlePaste(view, event) {
          const items = (
            event.clipboardData || event.originalEvent.clipboardData
          ).items;
          for (const item of items) {
            if (item.kind === "file" && item.type.startsWith("image/")) {
              const file = item.getAsFile();
              if (file) {
                uploadImageToS3(file).then((url) => {
                  if (url) {
                    view.dispatch(
                      view.state.tr.replaceSelectionWith(
                        view.state.schema.nodes.image.create({ src: url })
                      )
                    );
                  }
                });
                return true;
              }
            }
          }
          return false;
        },
      },
    });

    const fetchTokenAndConfigureAWS = useCallback(async () => {
      setIsLoading(true);
      try {
        const token = await getCurrentToken();
        setAccessToken(token);

        AWS.config.update({
          region: cognitoConfig.region,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognitoConfig.IdentityPoolIdFileUpload,
            Logins: {
              [cognitoConfig.userPoolLoginKey]: token,
            },
          }),
        });

        await new Promise((resolve, reject) => {
          AWS.config.credentials.refresh((err) => {
            if (err) {
              console.error("Error refreshing credentials:", err);
              reject(err);
            } else {
              resolve();
            }
          });
        });

        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching token or configuring AWS:", err);
        setIsLoading(false);
      }
    }, []);

    useEffect(() => {
      fetchTokenAndConfigureAWS();
    }, [fetchTokenAndConfigureAWS]);

    const uploadImageToS3 = async (file) => {
      const s3 = new AWS.S3();
      const fileName = `${Date.now()}-${file.name}`;
      const params = {
        Bucket: "feedback-public",
        Key: fileName,
        Body: file,
      };

      try {
        const data = await s3.upload(params).promise();
        return data.Location;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };

    const handleImageUpload = useCallback(() => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*"); // Restrict selection to image files
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        // Check if the selected file is an image
        if (file && file.type.startsWith("image/")) {
          const url = await uploadImageToS3(file);

          if (editor && url) {
            editor.chain().focus().setImage({ src: url }).run();
          }
        } else {
          alert("Please select a valid image file."); // Show message for non-image files
        }
      };
    }, [editor]);

    // const handleAddLink = useCallback(() => {
    //   const url = prompt("Enter the URL");

    //   if (url) {
    //     editor.chain().focus().setLink({ href: url }).run();
    //   }
    // }, [editor]);
    useImperativeHandle(ref, () => ({
      clearEditorContent: () => {
        editor?.commands.clearContent();
      },
    }));

    // Keep editor focused if it loses focus
    useEffect(() => {
      if (editor && !editor.isFocused) {
        editor.commands.focus();
      }
    }, [editor]);

    // Initialize content only once
    useEffect(() => {
      if (editor && !editorReady) {
        editor.commands.setContent(initialContent); // Initialize content
        setEditorReady(true); // Mark as ready
      }
    }, [editor, initialContent, editorReady]);

    const clearEditorContent = () => {
      if (editor) {
        editor.commands.clearContent();
      }
    };
    return (
      <>
        {editor ? (
          <div>
            <div className="toolbar">
              <button
                type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive("bold") ? "is-active" : ""}
              >
                Bold
              </button>
              <button
                type="button"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive("italic") ? "is-active" : ""}
              >
                Italic
              </button>
              <button
                type="button"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive("underline") ? "is-active" : ""}
              >
                Underline
              </button>

              <button
                type="button"
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive("paragraph") ? "is-active" : ""}
              >
                Paragraph
              </button>

              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
                className={
                  editor.isActive("heading", { level: 1 }) ? "is-active" : ""
                }
              >
                H1
              </button>
              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
                className={
                  editor.isActive("heading", { level: 2 }) ? "is-active" : ""
                }
              >
                H2
              </button>
              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 3 }).run()
                }
                className={
                  editor.isActive("heading", { level: 3 }) ? "is-active" : ""
                }
              >
                H3
              </button>
              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 4 }).run()
                }
                className={
                  editor.isActive("heading", { level: 4 }) ? "is-active" : ""
                }
              >
                H4
              </button>
              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 5 }).run()
                }
                className={
                  editor.isActive("heading", { level: 5 }) ? "is-active" : ""
                }
              >
                H5
              </button>
              <button
                type="button"
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 6 }).run()
                }
                className={
                  editor.isActive("heading", { level: 6 }) ? "is-active" : ""
                }
              >
                H6
              </button>
              <button
                type="button"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive("bulletList") ? "is-active" : ""}
              >
                Bullet list
              </button>
              <button
                type="button"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive("orderedList") ? "is-active" : ""}
              >
                Ordered list
              </button>
              <button type="button" onClick={handleImageUpload}>
                Upload Image
              </button>
              {/* <button type="button" onClick={handleAddLink}>
              Add Link
            </button> */}
            </div>
            <EditorContent editor={editor} />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </>
    );
  }
);

export default EditorComponent;
