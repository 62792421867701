import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import { PencilSquare, Search } from "react-bootstrap-icons";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { formatDate } from "../utils";
import AddTenant from "../components/tenant/AddTenant";
import ProjectContext from "../ProjectContext";

function TenantsList() {
  const [tenants, setTenants] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalListCount, setTotalListCount] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const { roles, userId } = useContext(ProjectContext);

  const fetchTenants = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const params = {
        page: currentPage,
        size: pageSize,
        sortColumn: "name",
        sortOrder: "ASC",
      };
      if (!roles.includes("SuperAdmin")) {
        params.userId = userId;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}tenant/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      setTenants(response.data.content);

      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
    } catch (error) {
      console.error("Error fetching model data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTenants();
  }, [currentPage]);
  const handleModelCreated = () => {
    setShow(false);
    fetchTenants();
  };
  const editTenant = (tenant) => {
    setSelectedTenant(tenant);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="d-flex align-items-center py-3">
        <h2 className="fw-bold mb-0">Tenants List</h2>
        {roles.includes("SuperAdmin") && (
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <button
              className="btn btn-sm btn-primary px-2 ms-2"
              onClick={() => {
                setSelectedTenant(null); // Reset selectedTenant to null
                setShow(true);
              }}
            >
              Add Tenant
            </button>
          </div>
        )}
      </div>
      <div className="table-responsive border rounded p-2">
        {loading ? (
          <p>Loading...</p>
        ) : tenants.length > 0 ? (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Tenant Name</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Models</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {tenants.map((tenant) => (
                <tbody key={tenant.id}>
                  <tr>
                    <td>{tenant.name}</td>
                    <td>
                      {tenant.createdAt ? formatDate(tenant.createdAt) : ""}
                    </td>
                    <td>{tenant.status}</td>
                    <td>
                      <ul className="ps-3 d-flex">
                      {tenant.models.length > 0
                        ? tenant.models.map((model) => (
                            <li key={model.id} className="me-4">
                              {model.name}
                            </li>
                          ))
                        : "-"}
                        </ul>
                    </td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() => editTenant(tenant)}
                      >
                        <PencilSquare size={18} />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p>No tenants found</p>
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedTenant ? "Edit Tenant" : "Add Tenant"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddTenant
            onModelCreated={handleModelCreated}
            tenant={selectedTenant}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TenantsList;
