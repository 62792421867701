import React, { useEffect, useState, useContext } from "react";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { Collapse } from "react-bootstrap";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { formatDateWithoutTime } from "../utils";

function Dashboard() {
  const { projectId, userId, roles, tenantId, groupId } = useContext(ProjectContext);
  const [actionCount, setActionCount] = useState([]);
  const [users, setUsers] = useState([]);
  const [userPageSize, setUserPageSize] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [totalListCount, setTotalListCount] = useState(0);

  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: userPageSize,
            search: "",
            status: "ACTIVE",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );
      setUserPageSize(response.data.pageInfo.totalElements);
      if (response.status === 200) {
        const reviewerUsers = response.data.content
          .map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName} (${user.userGroups})`,
          }));

        setUsers([{ value: null, label: "All Users" }, ...reviewerUsers]);
      } else {
        console.error("Error fetching users:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchActionCount = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const formattedStartDate = startDate
        ? new Date(startDate).toLocaleDateString("en-CA")
        : null;
      const formattedEndDate = endDate
        ? new Date(endDate).toLocaleDateString("en-CA")
        : null;
      let params = {
        page: currentPage,
        size: pageSize,
        sortOrder: "DESC",
        sortColumn: "id",
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectId: projectId,
      };
      if (roles.includes("ProjectAdmin")) {
        params.userId = selectedUser?.value || null;
      } else if (roles.includes("Reviewer")) {
        params.userId = userId;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/user-action-count/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      setActionCount(response.data.content);
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      setIsLoading(false);
    } catch (err) {
      console.log("Error fetching action count: ", err);
    }
  };
  useEffect(() => {
    if (projectId && (applyFilters || currentPage)) {
      fetchActionCount();
      if (applyFilters) {
        setApplyFilters(false);
      }
    }
  }, [projectId, applyFilters, currentPage]);
  const handleFilterClear = () => {
    setStartDate("");
    setEndDate("");
    setSelectedUser("");
    setApplyFilters(true);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleFilterApply = () => {
    setApplyFilters(true);
    setCurrentPage(1); // reset current page to 1
  };
  return (
    <div>
      <h2 className="fw-bold mb-0">Dashboard</h2>
      <div className="py-3">
        <div className="text-end">
          <button
            onClick={async () => {
              setShowFilters(!showFilters);
              if (!showFilters) {
                // Call fetchUsers when filters are about to be shown
                if (roles.includes("ProjectAdmin")) {
                  await fetchUsers();
                }
              }
            }}
            aria-controls="filter-collapse"
            aria-expanded={showFilters}
            className="btn mb-2 border-0 px-2 fw-bold text-primary"
          >
            {showFilters ? (
              <>
                <ChevronUp /> Hide
              </>
            ) : (
              <>
                <ChevronDown /> Show
              </>
            )}{" "}
            Filters
          </button>
        </div>
        <Collapse in={showFilters}>
          <div id="filter-collapse" className="border rounded-3 p-4">
            <div className="row">
              <div className="col-md-2">
                <div className="mb-3">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="mb-3">
                  <label>End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                {roles.includes("ProjectAdmin") && (
                  <div className="mb-3">
                    <label>User</label>
                    <Select
                      name="users"
                      options={users}
                      className="basic-multi-select me-2"
                      classNamePrefix="select"
                      placeholder="Filter by User"
                      onChange={setSelectedUser}
                      value={selectedUser}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="text-end">
              <button
                onClick={handleFilterClear}
                className="btn btn-secondary me-2"
              >
                Clear
              </button>
              <button
                onClick={handleFilterApply}
                className="btn btn-primary"
              >
                Filter
              </button>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="table-responsive">
        {isLoading ? (
          <p>Loading...</p>
        ) : actionCount.length > 0 ? (
          <>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  {roles.includes("ProjectAdmin") && <th>User</th>}
                  <th>Assigned Count</th>
                  {roles.includes("ProjectAdmin") &&  <th>Processed Count</th>}
                  <th>Issue Count</th>
                  <th>No Issue Count</th>
                  <th>Viewed Count</th>
                  <th>Resolved Count</th>
                  {/* <th>Downloaded Count</th> */}
                </tr>
              </thead>
              <tbody>
                {actionCount.map((action) => (
                  <tr key={action.id}>
                    <td>{action.actionDate ? formatDateWithoutTime(action.actionDate) : ""}</td>
                    {roles.includes("ProjectAdmin") && <td>{action.userName}</td>}
                    <td>{action.assignedCount}</td>
                    {roles.includes("ProjectAdmin") && <td>{action.processedCount}</td>}
                    <td>{action.issueCount}</td>
                    <td>{action.noIssueCount}</td>
                    <td>{action.viewedCount}</td>
                    <td>{action.resolvedCount}</td>
                    {/* <td>{action.downloadedCount}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange} 
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p>No data found</p>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
