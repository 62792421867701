import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../components/auth/Login';
import ForgotPassword from '../components/auth/ForgotPassword';

import Logo from "../assets/images/logo.png";
import ChangePassword from '../components/auth/ChangePassword';
import ResetPassword from '../components/auth/ResetPassword';

function AuthPage() {

  return (
    <section className="d-flex min-vh-100 align-items-center justify-content-center">
      <div className="login-container w-100 p-4 bg-white shadow-lg">
        <div className="navbar-brand text-center mb-4">
            <img src={Logo} alt="Logo" className="logo" />
        </div>
        <Routes>
          <Route path="/*" element={<Navigate to="/login" />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </div>
    </section>
  );
}

export default AuthPage;
