import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";

function AddModel({ onModelCreated, model }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(model ? model.name : "");
  const [description, setDescription] = useState(
    model ? model.description : ""
  );
  const [inputTypes, setInputTypes] = useState(
    model ? model.inputTypes.map((type) => ({ value: type, label: type })) : []
  );
  const [outputTypes, setOutputTypes] = useState(
    model ? model.outputTypes.map((type) => ({ value: type, label: type })) : []
  );
  const [lambdaName, setLambdaName] = useState(model ? model.lambdaName : "");
  const [status, setStatus] = useState(model ? model.status : "ACTIVE");

  const [nameError, setNameError] = useState("");
  const [inputTypesError, setInputTypesError] = useState("");
  const [outputTypesError, setOutputTypesError] = useState("");
  const [lambdaNameError, setLambdaNameError] = useState("");
  const inputOptions = [
    { value: "JPEG", label: "JPEG" },
    { value: "JPG", label: "JPG" },
    { value: "PNG", label: "PNG" },
    { value: "PDF", label: "PDF" },
    { value: "TIFF", label: "TIFF" },
    { value: "TIF", label: "TIF" },
  ];
  const outputOptions = [
    { value: "XLSX", label: "XLSX" },
    { value: "XLS", label: "XLS" },
    { value: "CSV", label: "CSV" },
    { value: "TXT", label: "TXT" },
    { value: "DOCX", label: "DOCX" },
    { value: "DOC", label: "DOC" },
    { value: "MP4", label: "MP4" },
    { value: "MP3", label: "MP3" },
    { value: "MOV", label: "MOV" },
    { value: "MPEG", label: "MPEG" },
    { value: "GIF", label: "GIF" },
    { value: "PPT", label: "PPT" },
    { value: "PPTX", label: "PPTX" },
    { value: "ZIP", label: "ZIP" },
    { value: "JSON", label: "JSON" },
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (name === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (inputTypes.length === 0) {
      setInputTypesError("At least one input type is required");
      isValid = false;
    } else {
      setInputTypesError("");
    }

    if (outputTypes.length === 0) {
      setOutputTypesError("At least one output type is required");
      isValid = false;
    } else {
      setOutputTypesError("");
    }

    if (lambdaName === "") {
      setLambdaNameError("Lambda name is required");
      isValid = false;
    } else {
      setLambdaNameError("");
    }

    if (!isValid) {
      return;
    }

    const token = await getCurrentToken();

    if (!token) {
      console.error("No token found");
      return;
    }

    const modelData = {
      name,
      description,
      inputTypes: inputTypes.map((option) => option.value),
      outputTypes: outputTypes.map((option) => option.value),
      lambdaName,
      status,
    };
    if (model) {
      modelData.id = model.id;
    }
    try {
      if (model) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}model`,
          modelData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}model`,
          [modelData],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      onModelCreated();
    } catch (error) {
      console.error("Error creating/updating model:", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            {nameError && <div className="text-danger">{nameError}</div>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="lambdaName" className="form-label">
              Lambda Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lambdaName"
              name="lambdaName"
              value={lambdaName}
              onChange={(event) => setLambdaName(event.target.value)}
            />
            {lambdaNameError && (
              <div className="text-danger">{lambdaNameError}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="inputType" className="form-label">
              Input Type
            </label>
            <Select
              isMulti
              options={inputOptions}
              value={inputTypes}
              onChange={(selectedOptions) => setInputTypes(selectedOptions)}
            />
            {inputTypesError && (
              <div className="text-danger">{inputTypesError}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="outputType" className="form-label">
              Output Type
            </label>
            <Select
              isMulti
              options={outputOptions}
              value={outputTypes}
              onChange={(selectedOptions) => setOutputTypes(selectedOptions)}
            />
            {outputTypesError && (
              <div className="text-danger">{outputTypesError}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select
              className="form-select"
              id="status"
              name="status"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className="text-end">
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  );
}

export default AddModel;
