import React, { useState } from "react";
import uploadIcon from "../assets/images/doc-upload-icon.png";
import { X } from "react-bootstrap-icons";

function MultiFileUpload({
  onFileUpload,
  handleFileSelect,
  selectedDate,
  handleDateChange,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (
      files.every(
        (file) =>
          file.type === "application/pdf" ||
          file.type === "application/msword" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
    ) {
      setSelectedFiles(files);
      setError(null);
      handleFileSelect(files);
    } else {
      setError("Invalid file type. Please upload a PDF, DOC or DOCX files");
    }
  };

  const handleUploadClick = () => {
    if (selectedFiles) {
      onFileUpload();
    }
  };
  const handleCancelClick = () => {
    setSelectedFiles([]);
  };
  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    handleFileSelect(updatedFiles);
  };

  return (
    <div className="file-upload-section">
      {selectedFiles.length === 0 ? (
        <label
          className="file-upload d-flex flex-column align-items-center justify-content-center p-4 py-5"
          htmlFor="inputGroupFile01"
        >
          <img src={uploadIcon} className="mb-2" alt="Upload Icon" />
          <span className="fs-4 font-fjalla">
            Drop your files here, or{" "}
            <span className="text-primary">browse</span>
          </span>
          <input
            type="file"
            className="d-none"
            id="inputGroupFile01"
            onChange={handleFileChange}
            multiple
          />
          {error && <span className="text-danger">{error}</span>}
        </label>
      ) : (
        <div className="text-center">
          <img src={uploadIcon} className="mb-2" alt="Upload Icon" />
          <div className="selected-files custom-scroll">
            {selectedFiles.map((file) => (
              <div
                key={file.name}
                className="d-inline-block me-3 mb-2 alert alert-dark border-0 py-1 px-2"
              >
                {file.name}
                <button
                  className="btn px-1 btn-sm ms-1 border-0 py-0"
                  onClick={() => handleRemoveFile(file)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className="my-4 mx-auto" style={{ maxWidth: "250px" }}>
            <label>Select date:</label>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="form-control"
            />
          </div>

          <button className="btn btn-danger mx-2" onClick={handleCancelClick}>
            Cancel
          </button>
          <button className="btn btn-primary mx-2" onClick={handleUploadClick}>
            Upload
          </button>
        </div>
      )}
    </div>
  );
}

export default MultiFileUpload;
