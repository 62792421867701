import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ProjectContext from "../../ProjectContext";
import Select from "react-select";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { ChevronDoubleLeft } from "react-bootstrap-icons";
import EditorComponent from "../editor/EditorComponent";

function EditPresetTag() {
  const location = useLocation();
  const { id } = useParams();
  const { projectId, userId, roles } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [subsectionOptions, setSubsectionOptions] = useState([]);
  const [issueTypeOptions, setIssueTypeOptions] = useState([]);
  const [issueNatureOptions, setIssueNatureOptions] = useState([]);
  const [templateChangeTypeOptions, setTemplateChangeTypeOptions] = useState(
    []
  );
  const [tagOptions, setTagOptions] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [subsectionId, setSubsectionId] = useState(null);
  const [issueNatureId, setIssueNatureId] = useState(null);
  const [issueTypeId, setIssueTypeId] = useState(null);
  const [templateChangeTypeId, setTemplateChangeTypeId] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [issueJson, setIssueJson] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const editorRef = useRef();
  const [sectionError, setSectionError] = useState("");
  const [subsectionError, setSubsectionError] = useState("");
  const [issueNatureError, setIssueNatureError] = useState("");
  const [issueTypeError, setIssueTypeError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const tag = location.state?.tag;
  const [titles, setTitles] = useState([]);
  const [sectionSelected, setSectionSelected] = useState(false);
  const fetchTitles = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "ASC",
        projectId: projectId,
        search: "",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const data = response.data.content;
      setTitles(data);

      setSectionOptions(
        data
          .filter((item) => item.type === "SECTION")
          .map((section) => ({ value: section.id, label: section.name }))
      );
      setSubsectionOptions(
        data
          .filter((item) => item.type === "SUBSECTION")
          .map((subsection) => ({
            value: subsection.id,
            label: subsection.name,
          }))
      );
      setIssueTypeOptions(
        data
          .filter((item) => item.type === "ISSUE_TYPE")
          .map((type) => ({ value: type.id, label: type.name }))
      );
      setIssueNatureOptions(
        data
          .filter((item) => item.type === "ISSUE_NATURE")
          .map((nature) => ({ value: nature.id, label: nature.name }))
      );
      setTemplateChangeTypeOptions(
        data
          .filter((item) => item.type === "TEMPLATE_CHANGE_TYPE")
          .map((templateChangeType) => ({
            value: templateChangeType.id,
            label: templateChangeType.name,
          }))
      );
      setTagOptions(
        data
          .filter((item) => item.type === "TAG")
          .map((tag) => ({ value: tag.id, label: tag.name }))
      );

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching titles:", error);
      setIsLoading(false);
    }
  }, [projectId]);
  useEffect(() => {
    if (projectId) {
      fetchTitles();
    }
  }, [projectId, fetchTitles]);
  useEffect(() => {
    if (sectionId) {
      const filteredSubsections = titles
        .filter(
          (item) =>
            item.type === "SUBSECTION" &&
            item.parentIssue.id === sectionId.value
        )
        .map((subsection) => ({
          value: subsection.id,
          label: subsection.name,
        }));
      setSubsectionOptions(filteredSubsections);
    } else {
      setSubsectionOptions([]);
      setSubsectionId(null);
    }
  }, [sectionId, titles]);

  useEffect(() => {
    if (
      tag &&
      sectionOptions.length &&
      subsectionOptions.length &&
      !sectionId
    ) {
      const sectionOption = sectionOptions.find(
        (option) => option.label === tag.issueJson.section
      );
      const subsectionOption = subsectionOptions.find(
        (option) => option.label === tag.issueJson.subsection
      );
      const issueTypeOption = issueTypeOptions.find(
        (option) => option.label === tag.issueJson.issueType
      );
      const issueNatureOption = issueNatureOptions.find(
        (option) => option.label === tag.issueJson.issueNature
      );
      const templateChangeTypeOption = templateChangeTypeOptions.find(
        (option) => option.label === tag.issueJson.templateChangeType
      );
      const tagOption = tagOptions.find(
        (option) => option.label === tag.issueJson.tag
      );
      if (sectionOption) setSectionId(sectionOption);
      if (subsectionOption) setSubsectionId(subsectionOption);
      if (issueTypeOption) setIssueTypeId(issueTypeOption);
      if (issueNatureOption) setIssueNatureId(issueNatureOption);
      if (templateChangeTypeOption)
        setTemplateChangeTypeId(templateChangeTypeOption);
      if (tagOption) setTagId(tagOption);
      setDescriptionTitle(tag.descriptionTitle);
      setDescription(tag.description);
      setIssueJson(tag.issueJson);
      setName(tag.name);
    }
  }, [
    tag,
    sectionOptions,
    subsectionOptions,
    issueTypeOptions,
    issueNatureOptions,
    tagOptions,
  ]);

  const handleSectionChange = (selectedOption) => {
    setSectionId(selectedOption ? selectedOption : null);
  };
  const handleSubsectionChange = (selectedOption) => {
    setSubsectionId(selectedOption);
  };

  const handleIssueTypeChange = (selectedOption) => {
    setIssueTypeId(selectedOption);
  };

  const handleIssueNatureChange = (selectedOption) => {
    setIssueNatureId(selectedOption);
  };

  const handleTemplateChangeTypeChange = (selectedOption) => {
    setTemplateChangeTypeId(selectedOption);
  };

  const handleTagChange = (selectedOption) => {
    setTagId(selectedOption);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Reset error messages
    setSectionError("");
    setSubsectionError("");
    setIssueNatureError("");
    setIssueTypeError("");
    setNameError("");

    // Validate form
    if (!name) {
      setNameError("Name is required.");
      return;
    }
    if (!sectionId) {
      setSectionError("Section is required.");
      return;
    }
    if (!subsectionId) {
      setSubsectionError("Subsection is required.");
      return;
    }
    if (!issueTypeId) {
      setIssueTypeError("Issue Type is required.");
      return;
    }
    if (!issueNatureId) {
      setIssueNatureError("Issue Nature is required.");
      return;
    }
    const issueJsonObj = {
      section: sectionId ? sectionId.label : null,
      subsection: subsectionId ? subsectionId.label : null,
      issueNature: issueNatureId ? issueNatureId.label : null,
      issueType: issueTypeId ? issueTypeId.label : null,
      templateChangeType: templateChangeTypeId
        ? templateChangeTypeId.label
        : null,
      tag: tagId ? tagId.label : null,
    };
    const issueJson = JSON.stringify(issueJsonObj);
    const issueData = [
      {
        id: tag.id,
        name: name,
        sectionId: sectionId ? sectionId.value : null,
        subsectionId: subsectionId ? subsectionId.value : null,
        issueNatureId: issueNatureId ? issueNatureId.value : null,
        issueTypeId: issueTypeId ? issueTypeId.value : null,
        templateChangeTypeId: templateChangeTypeId
          ? templateChangeTypeId.value
          : null,
        tagId: tagId ? tagId.value : null,
        descriptionTitle,
        description,
        issueJson,
        createdBy: tag.createdBy,
        updatedBy: userId,
        status:
          Array.isArray(roles) && roles.includes("ProjectAdmin")
            ? "ACTIVE"
            : "PENDING_REVIEW",
      },
    ];
    try {
      const token = await getCurrentToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}preset-tag`,
        {
          projectId: projectId,
          presetTagAddSubDto: issueData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Set the message
      setMessage("Issue updated successfully!");
      // Hide the message after 3 seconds
      setTimeout(() => {
        setMessage("");
        window.history.back();
      }, 1000);
    } catch (error) {
      console.error("Error posting issue:", error);
      // Set the error message
      setError("Error submitting issue(s). Please try again.");
      // Hide the message after 3 seconds
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const resetForm = () => {
    setSectionId(null);
    setSubsectionId(null);
    setIssueNatureId(null);
    setIssueTypeId(null);
    setTemplateChangeTypeId(null);
    setTagId(null);
    setDescriptionTitle("");
    setDescription("");
    editorRef.current.clearEditorContent();
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const handleContentChange = (newContent) => {
    setDescription(newContent);
  };

  return (
    <>
      <div className="pt-3 pb-2 d-flex align-items-center justify-content-between">
        <h2 className="fw-bold mb-0">Edit Preset Tag</h2>
        <button
          className="btn btn-sm btn-outline-primary px-3"
          onClick={handleBackClick}
        >
          <ChevronDoubleLeft className="dark-icon-color" /> Back
        </button>
      </div>
      {message && (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <div className="text-danger">{nameError}</div>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Select
                placeholder="Select Section"
                isClearable
                options={sectionOptions}
                value={sectionId}
                onChange={handleSectionChange}
              />

              {sectionError && (
                <div className="text-danger">{sectionError}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Select
                placeholder="Select Subsection"
                isClearable
                isDisabled={!sectionId}
                options={subsectionOptions}
                value={
                  subsectionOptions.find(
                    (option) =>
                      option.value === (subsectionId && subsectionId.value)
                  ) || subsectionId
                }
                onChange={handleSubsectionChange}
              />

              {subsectionError && (
                <div className="text-danger">{subsectionError}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Select
                options={issueTypeOptions}
                placeholder="Select Issue Type"
                isClearable
                onChange={handleIssueTypeChange}
                value={issueTypeId ? issueTypeId : ""}
              />
              {issueTypeError && (
                <div className="text-danger">{issueTypeError}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Select
                options={issueNatureOptions}
                placeholder="Select Issue Nature"
                isClearable
                onChange={handleIssueNatureChange}
                value={issueNatureId ? issueNatureId : ""}
              />
              {issueNatureError && (
                <div className="text-danger">{issueNatureError}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <Select
                options={templateChangeTypeOptions}
                placeholder="Select Template Change Type"
                isClearable
                onChange={handleTemplateChangeTypeChange}
                value={templateChangeTypeId ? templateChangeTypeId : ""}
              />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <Select
                options={tagOptions}
                placeholder="Select Tag"
                isClearable
                onChange={handleTagChange}
                value={tagId ? tagId : ""}
              />
            </div>
          </div> */}
        </div>
        <h5 className="fw-bold">Description</h5>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Description Title"
          value={descriptionTitle}
          onChange={(e) => setDescriptionTitle(e.target.value)}
        />
        {description && (
          <EditorComponent
            initialContent={description}
            onContentChange={handleContentChange}
            ref={editorRef}
          />
        )}
        {!description && (
          <EditorComponent
            onContentChange={handleContentChange}
            ref={editorRef}
          />
        )}
        <div className="text-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default EditPresetTag;
