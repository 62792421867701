import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  getCurrentToken,
  getCurrentUser,
} from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import Select from "react-select";

function SwitchTenant() {
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const { roles, userId, tenantId, setTenantId } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentUser();
      setCognitoSub(user.sub);
    };
    fetchUser();
  }, []);
  useEffect(() => {
    if (cognitoSub) {
      fetchTenants(cognitoSub);
    }
  }, [cognitoSub]);

  const fetchTenants = async (cognitoSub) => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const userResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const uniqueTenants = [];
      const tenantsData = [];

      userResponse.data.userGroups.forEach((group) => {
        if (!uniqueTenants.includes(group.tenantId)) {
          uniqueTenants.push(group.tenantId);
          tenantsData.push({
            value: group.tenantId,
            label: group.tenant,
          });
        }
      });

      setTenants(tenantsData);

      const currentTenant = tenantsData.find(
        (tenant) => tenant.value === tenantId
      );
      setSelectedTenant(currentTenant);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const switchTenant = async (tenantId) => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      let latestProjectId = null;
      // if (roles.includes("ProjectAdmin") || roles.includes("Reviewer")) {
      //   const userResponse = await axios.get(
      //     `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   );

      //   const tenantProjects = userResponse.data.userGroups.filter(group => group.tenantId === tenantId);
      //   const sortedProjects = tenantProjects.sort((a, b) => new Date(b.lastAccessedOn) - new Date(a.lastAccessedOn));

      //   if (sortedProjects.length > 0) {
      //     latestProjectId = sortedProjects[0].projectId;
      //   }
      // }
      const userResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Filter userGroups for the selected tenant
      const tenantGroups = userResponse.data.userGroups.filter(
        (group) => group.tenantId === tenantId
      );
      // Check if the tenant has roles 'ProjectAdmin' or 'Reviewer'
      const hasRelevantRoles = tenantGroups.some(
        (group) => group.group === "ProjectAdmin" || group.group === "Reviewer"
      );

      if (hasRelevantRoles) {
        // Sort tenant projects by lastAccessedOn to find the latest project
        const sortedProjects = tenantGroups
          .filter((group) => group.projectId !== null) // Include only projects with IDs
          .sort(
            (a, b) => new Date(b.lastAccessedOn) - new Date(a.lastAccessedOn)
          );

        if (sortedProjects.length > 0) {
          latestProjectId = sortedProjects[0].projectId; // Select the latest project ID
        }
      }

      const data = {
        userId,
        tenantId,
      };

      if (latestProjectId) {
        data.projectId = latestProjectId;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/last-accessed-on`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTenantId(tenantId);
      window.location.reload();
    } catch (err) {
      console.log("Error in switchProject:", err);
    }
  };
  const handleChange = (selectedOption) => {
    setSelectedTenant(selectedOption);
    switchTenant(selectedOption.value, selectedOption.label);
  };

  return (
    <div className="pt-2">
      <Select
        options={tenants}
        value={selectedTenant}
        onChange={handleChange}
        isLoading={loading}
      />
    </div>
  );
}

export default SwitchTenant;
