import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select, { components } from "react-select";
import ProjectContext from "../../ProjectContext";

function EditProject({ project, handleClose, triggerRefresh }) {
  const [projectName, setProjectName] = useState(project.name);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [projectNameError, setProjectNameError] = useState("");
  const { roles, groupId, userId, tenantId } = useContext(ProjectContext);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantNameError, setTenantNameError] = useState("");
  const [removedUsers, setRemovedUsers] = useState([]);

  useEffect(() => {
    fetchProjectUsers();
  }, []);

  useEffect(() => {
    // Fetch users when pageSize changes
    const fetchUsers = async () => {
      try {
        const params = {
          page: 1,
          size: "", // use pageSize
          search: "",
          status: "ACTIVE",
          sortColumn: "id",
          sortOrder: "DESC",
          group: "",
          logginGroupId: groupId,
          userId: userId,
          tenantId: tenantId,
          isMyUsers: true,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: params,
          }
        );
        if (response.status === 200) {
          setUsers(
            response.data.content
              .filter((user) => !user.userGroups.includes("SuperAdmin"))
              .map((user) => ({
                value: user.id,
                label: `${user.firstName} ${
                  user.lastName
                } (${user.userGroups.join(", ")})`,
                role: user.userGroups.length > 0 ? user.userGroups[0] : null,
                userGroupId: user.userGroupList?.find(
                  (group) => project?.id && group.projectId === project.id
                )?.userGroupId,
                isDisabled:
                  roles.includes("ProjectAdmin") &&
                  user.userGroups.includes("ProjectAdmin"),
              }))
          );
        } else {
          console.error("Error fetching users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []); // re-run when pageSize changes
  useEffect(() => {
    const fetchModels = async () => {
      setLoading(true);
      try {
        const token = await getCurrentToken();

        if (!token) {
          console.error("No token found");
          return;
        }
        const params = {
          page: 1,
          size: "",
          search: "",
          status: "ACTIVE",
          sortColumn: "id",
          sortOrder: "DESC",
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}model/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: params,
          }
        );

        setModels(response.data.content);
        // Set the current model in the selectedModel state variable
        if (project && project.model && project.model.id) {
          const model = response.data.content.find(
            (m) => m.id === project.model.id
          );
          if (model) {
            setSelectedModel({ value: model.id, label: model.name });
          }
        }
      } catch (error) {
        console.error("Error fetching model data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, [project]);
  const fetchProjectUsers = async () => {
    try {
      const params = {
        page: 1,
        size: "",
        search: "",
        status: "",
        sortColumn: "id",
        sortOrder: "DESC",
        group: "",
        projectId: project.id,
        logginGroupId: groupId,
        userId: userId,
        tenantId: tenantId,
        isMyUsers: true,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
          params: params,
        }
      );
      if (response.status === 200) {
        const users = response.data.content.map((user) => {
          const role =
            user.userGroups && user.userGroups.length > 0
              ? user.userGroups[0]
              : null;

          // Check if user.userGroupList exists
          if (user.userGroupList) {
            // Try to find the user group matching the project ID
            const userGroup = user.userGroupList.find(
              (group) => group.projectId === project.id
            );

            return {
              value: user.id,
              label: `${user.firstName} ${
                user.lastName
              } (${user.userGroups.join(", ")})`,
              role: role,
              userGroupId: userGroup ? userGroup.userGroupId : null, // Assign groupId if found
              isDisabled:
                roles.includes("ProjectAdmin") &&
                user.userGroups.includes("ProjectAdmin"),
            };
          } else {
            // Log the case where userGroupList is undefined
            console.warn("User has no userGroupList", user);
            return {
              value: user.id,
              label: `${user.firstName} ${
                user.lastName
              } (${user.userGroups.join(", ")})`,
              role: role,
              userGroupId: null, // No group found, so null
              isDisabled:
                roles.includes("ProjectAdmin") &&
                user.userGroups.includes("ProjectAdmin"),
            };
          }
        });
        setSelectedUsers(users);
      } else {
        console.error("Error fetching project users:", response);
      }
    } catch (error) {
      console.error("Error fetching project users:", error);
    }
  };
  const fetchTenants = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "ASC",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}tenant/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const tenantsData = response.data.content.map((tenant) => ({
        value: tenant.id,
        label: tenant.name,
      }));

      setTenants(tenantsData);
      // Set the current tenant in the selectedTenant state variable
      if (project && project.tenantName) {
        const tenant = tenantsData.find((t) => t.label === project.tenantName);
        if (tenant) {
          setSelectedTenant(tenant);
        }
      }
    } catch (error) {
      console.error("Error fetching model data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projectName) {
      setProjectNameError("Project Name is required");
      return;
    }
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project`,
        {
          id: project.id,
          name: projectName,
          modelId: selectedModel ? selectedModel.value : null,
          tenantId: tenantId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        await updateProjectUsers();
        triggerRefresh(); // refresh the project list
        handleClose(); // close the modal
      } else {
        console.error("Error updating project:", response);
      }
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };
  const updateProjectUsers = async () => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const newUsers = selectedUsers.filter((user) => !user.userGroupId);
      const userGroups = newUsers.map((user) => {
        let groupId;
        switch (user.role) {
          case "SuperAdmin":
            groupId = 1;
            break;
          case "Reviewer":
            groupId = 2;
            break;
          case "ProjectAdmin":
            groupId = 3;
            break;
          case "TenantAdmin":
            groupId = 4;
            break;
          case "Developer":
            groupId = 5;
            break;
          default:
            groupId = null;
        }

        return {
          userId: user.value,
          tenantId: tenantId,
          projectId: project.id,
          groupId: groupId,
          status: "ACTIVE",
        };
      });

      const removedUserGroups = removedUsers
        .filter((user) => user.userGroupId !== null) // Ensure only users with valid userGroupId are sent for removal
        .map((user) => ({
          userGroupId: user.userGroupId,
          status: "INACTIVE",
        }));

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        [...userGroups, ...removedUserGroups],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Error updating project users:", response);
      }
    } catch (error) {
      console.error("Error updating project users:", error);
    }
  };
  const MultiValueRemove = (props) => {
    if (props.data.isDisabled) {
      return null; // Don't return the remove option for disabled options
    }
    return <components.MultiValueRemove {...props} />;
  };
  const handleUserChange = (selectedOptions) => {
    const removed = selectedUsers.filter(
      (user) => !selectedOptions.some((option) => option.value === user.value)
    );

    removed.forEach((user) => {
      if (user.userGroupId === null) {
        console.warn(`User ${user.label} has a null userGroupId`);
      }
    });

    setRemovedUsers((prevRemovedUsers) => [...prevRemovedUsers, ...removed]);
    setSelectedUsers(selectedOptions);
  };

  // Log the updated removedUsers after state update
  useEffect(() => {
    //console.log("removedUsers", removedUsers);
  }, [removedUsers]);

  return (
    <div>
      <Modal.Header>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Project Name</label>
            <input
              type="text"
              className="form-control"
              name="projectName"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectNameError("");
              }}
              disabled={!roles.includes("SuperAdmin")} // disable if role is not SuperAdmin
            />
            {projectNameError && (
              <div className="text-danger">{projectNameError}</div>
            )}
          </div>
          {/* <div className="mb-3">
            <label>Tenant</label>
            <Select
              isClearable
              name="tenants"
              options={tenants}
              value={selectedTenant}
              onChange={setSelectedTenant}
            />
            {tenantNameError && (
              <div className="text-danger">{tenantNameError}</div>
            )}
          </div> */}
          <div className="mb-3">
            <label>Users</label>
            <Select
              isMulti
              name="users"
              options={users}
              value={selectedUsers}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleUserChange}
              components={{ MultiValueRemove }}
            />
          </div>
          <div className="mb-3">
            <label>Model</label>
            <Select
              isClearable
              name="models"
              options={models.map((model) => ({
                value: model.id,
                label: model.name,
              }))}
              value={selectedModel}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedModel}
            />
          </div>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default EditProject;
