import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import { Modal, Carousel, Badge } from "react-bootstrap";
import { EyeFill, PencilSquare } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";

function IssueSlide({ fileId }) {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const { projectId, roles, userId } = useContext(ProjectContext);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const fetchIssues = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        search: "",
        sortColumn: "name",
        sortOrder: "ASC",
        status: "",
        projectId: projectId,
        //createdBy: roles.includes("Reviewer") ? userId : filterCreatedBy,
        fileId: fileId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/list-issue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      const parsedIssues = response.data.content.map((issue) => {
        let issueJson = {};
        try {
          if (issue.issueJson) {
            issueJson = JSON.parse(issue.issueJson);
          }
        } catch (error) {
          console.error("Error parsing issueJson:", error);
        }
        return {
          ...issue,
          issueJson,
        };
      });

      setIssues(parsedIssues);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchIssues();
    }
  }, [projectId]);
  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };
  const handleEdit = (issue) => {
    navigate(`/edit-issue/${issue.id}`, { state: { issue } });
  };
  return (
    <div className="pt-4">
      <div className="d-flex align-items-center mb-3">
        <h5 className="fw-bold m-0 me-2">Current Issues</h5>{" "}
        <Badge pill bg="primary">
          {issues.length}
        </Badge>
        {/* {issues.length > 0 && (
          <p className="m-0">
            {activeIndex + 1} of {issues.length} issues
          </p>
        )} */}
      </div>
      <div className="table-responsive current-issues">
        {loading ? (
          <p>Loading...</p>
        ) : issues.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>File Id</th>
                <th>Issue Id</th>
                <th>Issue</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {issues.map((issue, index) => (
                <tr key={index}>
                  <td>{issue.name}</td>
                  <td>{issue.fileId}</td>
                  <td>{issue.id}</td>
                  <td>
                    {issue.issueJson && (
                      <ul className="ps-3 m-0">
                        {issue.issueJson.section && (
                          <li>
                            Section: <strong>{issue.issueJson.section}</strong>
                          </li>
                        )}
                        {issue.issueJson.subsection && (
                          <li>
                            Subsection:{" "}
                            <strong>{issue.issueJson.subsection}</strong>
                          </li>
                        )}
                        {issue.issueJson.issueNature && (
                          <li>
                            Issue Nature:{" "}
                            <strong>{issue.issueJson.issueNature}</strong>
                          </li>
                        )}
                        {issue.issueJson.issueType && (
                          <li>
                            Issue Type:{" "}
                            <strong>{issue.issueJson.issueType}</strong>
                          </li>
                        )}
                        {issue.issueJson.templateChangeType && (
                          <li>
                            Template Change Type:{" "}
                            <strong>
                              {issue.issueJson.templateChangeType}
                            </strong>
                          </li>
                        )}
                        {issue.issueJson.tag && (
                          <li>
                            Tag: <strong>{issue.issueJson.tag}</strong>
                          </li>
                        )}
                      </ul>
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-sm px-2 py-1 btn-outline-primary"
                      onClick={() => setSelectedIssue(issue)}
                      disabled={!issue.description && !issue.descriptionTitle}
                    >
                      <EyeFill size={20} />
                    </button>
                  </td>
                  <td>{issue.status}</td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={() => handleEdit(issue)}
                    >
                      <PencilSquare size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No Issues found.</p>
        )}
      </div>
      {selectedIssue && (
        <Modal
          show={selectedIssue}
          size="lg"
          onHide={() => setSelectedIssue(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedIssue.descriptionTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="issue-desc">
            <div
              dangerouslySetInnerHTML={{ __html: selectedIssue.description }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setSelectedIssue(null)}
              className="btn btn-sm btn-secondary"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
export default IssueSlide;
