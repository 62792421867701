import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import { PencilSquare, Search } from "react-bootstrap-icons";
import AddModel from "../components/models/AddModel";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { set } from "immutable";

function ModelList() {
  const [models, setModels] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPageSize, setTotalPageSize] = useState(1);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalListCount, setTotalListCount] = useState(0);

  const fetchModels = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const statusString = selectedStatus
        .map((option) => option.value)
        .join(",");
      const params = {
        page: currentPage,
        size: pageSize,
        search: searchQuery,
        status: statusString || "",
        sortColumn: "id",
        sortOrder: "DESC",
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}model/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      setModels(response.data.content);

      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
    } catch (error) {
      console.error("Error fetching model data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchModels();
  }, [selectedStatus, searchQuery, currentPage]);
  const handleModelCreated = () => {
    setShow(false);
    fetchModels();
  };
  const editModel = (model) => {
    setSelectedModel(model);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedModel(null); // Reset selectedModel when modal is closed
  };
  const handleCheckboxChange = (id, checked) => {
    if (checked) {
      setSelectedModels((prev) => [...prev, id]);
    } else {
      setSelectedModels((prev) => prev.filter((modelId) => modelId !== id));
    }
  };

  const toggleModelStatus = async (ids, status) => {
    const token = await getCurrentToken();

    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}model/status`,
        {
          ids: ids,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchModels();
    } catch (error) {
      console.error("Error toggling model status:", error);
    }
  };
  const toggleSelectedModelsStatus = async () => {
    const token = await getCurrentToken();

    if (!token) {
      console.error("No token found");
      return;
    }

    const selectedModelsData = models.filter((model) =>
      selectedModels.includes(model.id)
    );
    const activeModels = selectedModelsData
      .filter((model) => model.status === "ACTIVE")
      .map((model) => model.id);
    const inactiveModels = selectedModelsData
      .filter((model) => model.status === "INACTIVE")
      .map((model) => model.id);

    try {
      if (activeModels.length > 0) {
        await toggleModelStatus(activeModels, "INACTIVE");
      }
      if (inactiveModels.length > 0) {
        await toggleModelStatus(inactiveModels, "ACTIVE");
      }
      setSelectedModels([]);
    } catch (error) {
      console.error("Error toggling selected models status:", error);
    }
  };
  const statuses = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="d-flex align-items-center py-3">
        <h2 className="fw-bold mb-0">Model List</h2>
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <div className="input-group w-auto me-2">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search List"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          <Select
            placeholder="Filter by status"
            className="w-auto me-2"
            options={statuses}
            onChange={(selectedOption) => {
              setSelectedStatus(selectedOption || null);
              setCurrentPage(1);
            }}
            value={selectedStatus}
            isMulti
          />
          <button
            className="btn btn-sm px-2 btn-outline-primary"
            onClick={toggleSelectedModelsStatus}
            disabled={selectedModels.length === 0}
          >
            Change Status
          </button>
          <button
            className="btn btn-sm btn-primary px-2 ms-2"
            onClick={() => setShow(true)}
          >
            Create Model
          </button>
        </div>
      </div>
      <div className="table-responsive border rounded p-2">
        {loading ? (
          <p>Loading...</p>
        ) : models.length > 0 ? (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Input Type</th>
                  <th>Output Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {models.map((model) => (
                <tbody key={model.id}>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleCheckboxChange(model.id, e.target.checked)
                        }
                      />
                    </td>
                    <td>{model.name}</td>
                    <td>{model.description}</td>
                    <td>{model.inputTypes.join(", ")}</td>
                    <td>{model.outputTypes.join(", ")}</td>
                    <td>{model.status}</td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() => editModel(model)}
                      >
                        <PencilSquare size={18} />
                      </button>
                      <button
                        className={`btn btn-sm px-2 py-1 ${
                          model.status === "ACTIVE"
                            ? "btn-danger"
                            : "btn-success"
                        } fs-sm`}
                        onClick={() =>
                          toggleModelStatus(
                            [model.id],
                            model.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                          )
                        }
                      >
                        {model.status === "ACTIVE" ? "Deactivate" : "Activate"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p>No models found</p>
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedModel ? "Edit Model" : "Add Model"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddModel onModelCreated={handleModelCreated} model={selectedModel} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModelList;
