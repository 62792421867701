import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { Modal, OverlayTrigger, Popover, Overlay } from "react-bootstrap";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import { Trash3 } from "react-bootstrap-icons";
import AddGroup from "./AddGroup";
import AddGroupAdmin from "./AddGroupAdmin";

function EditUser({ user, handleClose, triggerRefresh, setUser }) {
  const { roles, userId, tenantId, projectId } = useContext(ProjectContext);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.userGroups.join(","),
    phone: user.phone,
    project: user.projects.map((project) => project.id),
  });
  const [userDetails, setUserDetails] = useState({
    ...user,
    userGroupList: user.userGroupList || [],
  });
  const [showPopover, setShowPopover] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const buttonRefs = useRef({});
  // Function to fetch updated user details, including userGroupList
  const fetchUserDetails = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${user.cognitoSub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedUser = response.data;
      const userGroupList = updatedUser.userGroups || [];
      setUserDetails({ ...updatedUser, userGroupList }); // Update the local user details

      setFormData({
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        email: updatedUser.email,
        role: userGroupList.map((group) => group.group).join(", "), // Convert the array of groups into a comma-separated string
        phone: updatedUser.phone,
        project: userGroupList.map((group) => group.projectId), // Extract project IDs from user groups
      });
    } catch (error) {
      console.error("Error fetching updated user details:", error);
    }
  };
  useEffect(() => {
    // Update role options based on userGroupList changes
    const baseRoleOptions = [
      { value: "SuperAdmin", label: "Super Admin" },
      { value: "Reviewer", label: "Reviewer" },
      { value: "ProjectAdmin", label: "Project Admin" },
      { value: "Developer", label: "Developer" },
      { value: "TenantAdmin", label: "Tenant Admin" },
    ];
    const rolesInUserGroups = userDetails.userGroupList.map(
      (group) => group.group
    );

    // Calculate allowed roles dynamically
    const updatedRoleOptions =
      rolesInUserGroups.length === 0 || rolesInUserGroups.includes("SuperAdmin")
        ? baseRoleOptions
        : baseRoleOptions.filter(
            (role) => role.value !== "SuperAdmin" && role.value !== "Developer"
          );

    setRoleOptions(updatedRoleOptions);
  }, [userDetails.userGroupList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    // Sort the userGroupList array by the lastAccessed timestamp in descending order
    const sortedUserGroupList = [...user.userGroupList].sort(
      (a, b) => new Date(b.lastAccessed) - new Date(a.lastAccessed)
    );

    // Get the userGroupId of the first item in the sorted array (the most recently accessed user group)
    const userGroupId =
      sortedUserGroupList.length > 0
        ? sortedUserGroupList[0].userGroupId
        : null;
    // Check if first name is provided
    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
    }

    // Check if last name is provided
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
    }

    // Check if project is selected when role is not SuperAdmin
    // if (formData.role !== "SuperAdmin" && formData.project.length === 0) {
    //   newErrors.project = "Project is required";
    // }

    // If there are any errors, don't submit the form
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const token = await getCurrentToken();
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user`,
        {
          id: user.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          //projectIds: formData.project.map((projectId) => parseInt(projectId)),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      triggerRefresh(); // Refresh the user list
      fetchUserDetails(); // Fetch the updated user details
      handleClose(); // Close the modal after updating the user
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const isProjectAdmin = roles.includes("ProjectAdmin");

  

  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
                {errors.firstName && (
                  <p className="text-danger">{errors.firstName}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
                {errors.lastName && (
                  <p className="text-danger">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
              </div>
            </div>
          </div>
          {!roles.includes("SuperAdmin")
            ? !userDetails.userGroupList.some(
                (group) => group.group === "Developer"
              ) && (
                <AddGroup
                  user={user}
                  refreshGroup={fetchUserDetails}
                  triggerRefresh={triggerRefresh} 
                  setUser={setUser}
                />
              )
            : !userDetails.userGroupList.some(
                (group) =>
                  group.group === "SuperAdmin" || group.group === "Developer"
              ) && (
                <AddGroupAdmin
                  user={user}
                  roleOptions={roleOptions}
                  refreshGroup={fetchUserDetails}
                  triggerRefresh={triggerRefresh}
                />
              )}

          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}

export default EditUser;
