import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  isLoggedIn,
  fetchUserRoles,
  refreshSession,
} from "./services/cognito/cognitoAuth";
import AuthPage from "./pages/AuthPage";
import AuthContext from "./components/auth/AuthContext";
import ChangePassword from "./components/auth/ChangePassword";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ProjectContext from "./ProjectContext";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [canRender, setCanRender] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [projectName, setProjectName] = useState(null);
  const [userId, setUserId] = useState(null);
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [refreshFiles, setRefreshFiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [groupId, setGroupId] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [userGroupId, setUserGroupId] = useState(null);
  const [refreshProcessed, setRefreshProcessed] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Check if the user is logged in
        const loggedIn = await isLoggedIn();
        // Set authentication state
        setAuthenticated(loggedIn);

        if (loggedIn) {
          localStorage.setItem("setAuthenticated", true);
        } else {
          localStorage.setItem("setAuthenticated", false);
        }

        // Allow rendering of the application
        setCanRender(true);
      } catch (error) {
        console.error("Error during authentication initialization:", error);
        // Allow rendering of the application even if there's an error
        setCanRender(true);
      }
    };

    initializeAuth();
  }, []);

  // Set up global error handling
  let isRefreshing = false;
  let refreshSubscribers = [];

  const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
  };

  const onRefreshed = (token) => {
    refreshSubscribers.map((cb) => cb(token));
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (!error.response) {
        setErrorMessage("Network Error");
      } else if (error.message === "Refresh Token has expired") {
        setErrorMessage("Timeout Error");
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "The incoming token has expired"
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const session = await refreshSession();
            const newToken = session.getIdToken().getJwtToken();
            isRefreshing = false;
            onRefreshed(newToken);
            refreshSubscribers = [];
          } catch (err) {
            isRefreshing = false;
            setErrorMessage("Failed to refresh token");
            return Promise.reject(error);
          }
        }

        const retryOriginalRequest = new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            resolve(axios(originalRequest));
          });
        });

        return retryOriginalRequest;
      } else {
        let errorMessage = "An error occurred";
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (
          error.response.data &&
          typeof error.response.data === "string"
        ) {
          errorMessage = error.response.data;
        } else if (error.response.message) {
          errorMessage = error.response.message;
        }

        setErrorMessage(errorMessage);
      }
      return Promise.reject(error);
    }
  );

  const closeModal = () => {
    setErrorMessage(null);
  };
  const safeSetRoles = (roles) => setRoles(Array.isArray(roles) ? roles : []);

  return canRender ? (
    <>
      <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
        <ProjectContext.Provider
          value={{
            projectId,
            setProjectId,
            roles,
            setRoles: safeSetRoles, // Ensure roles is always an array
            projectName,
            setProjectName,
            userId,
            setUserId,
            currentPage,
            setCurrentPage,
            groupId,
            setGroupId,
            tenantId,
            setTenantId,
            userGroupId,
            setUserGroupId,
            refreshProcessed,
            setRefreshProcessed,
          }}
        >
          <Router>
            {authenticated ? (
              <Routes>
                <Route path="/" element={<AuthPage />}>
                  <Route path="change-password" element={<ChangePassword />} />
                </Route>
                <Route path="/*" element={<AuthenticatedRoutes />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<AuthPage />} />
                <Route path="/*" element={<AuthPage />} />
              </Routes>
            )}
          </Router>
        </ProjectContext.Provider>
      </AuthContext.Provider>
      <Modal show={!!errorMessage} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-danger fs-6">
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div className="text-center d-flex w-100 vh-100 align-items-center justify-content-center">
      Loading...
    </div>
  );
}

export default App;
