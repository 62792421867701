import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";

function ProjectUsers({ projectId, projectName }) {
  const [users, setUsers] = useState([]);
  const { groupId, userId, tenantId } = useContext(ProjectContext);
  useEffect(() => {
    if (projectId) {
      fetchProjectUsers();
    }
  }, [projectId]);

  const fetchProjectUsers = async () => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "",
            logginGroupId: groupId,
            projectId: projectId,
            userId: userId,
            tenantId: tenantId,
            isMyUsers: true,
          },
        }
      );
      if (response.status === 200) {
        setUsers(response.data.content);
      } else {
        console.error("Error fetching project users:", response);
      }
    } catch (error) {
      console.error("Error fetching project users:", error);
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{projectName} Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-group proj-users">
          {users.length > 0 ? (
            users.map((user) => (
              <li className="list-group-item" key={user.id}>
                {user.firstName} {user.lastName}
              </li>
            ))
          ) : (
            <li className="list-group-item">No users found.</li>
          )}
        </ul>
      </Modal.Body>
    </>
  );
}

export default ProjectUsers;
