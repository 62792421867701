import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { PlusCircle, Trash3 } from "react-bootstrap-icons";
import { Popover, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProjectContext from "../../ProjectContext";

function AddGroupAdmin({ user, triggerRefresh, refreshGroup, roleOptions }) {
  const [projects, setProjects] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [formData, setFormData] = useState({
    role: null,
    project: [],
    tenant: [],
  });
  const [showForm, setShowForm] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    role: "",
    project: "",
    tenant: "",
  });
  const [showPopover, setShowPopover] = useState(null);
  const buttonRefs = React.useRef({});
  const [userDetails, setUserDetails] = useState({
    ...user,
    userGroupList: user.userGroupList || [],
  });
  const { tenantId } = useContext(ProjectContext);
  const [allProjects, setAllProjects] = useState([]);
  const [allTenants, setAllTenants] = useState([]);

  const fetchUserDetails = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${user.cognitoSub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedUser = response.data;
      const userGroupList = updatedUser.userGroups || [];
      const updatedDetails = { ...updatedUser, userGroupList };
      setUserDetails(updatedDetails); // Update local state

      return updatedDetails; // Return for immediate use
    } catch (error) {
      console.error("Error fetching updated user details:", error);
    }
  };
  // Fetch tenants data
  const fetchTenants = async () => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }
  
      const tenantResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}tenant/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            sortColumn: "name",
            sortOrder: "ASC",
          },
        }
      );
  
      const tenantData = tenantResponse.data;
  
      if (Array.isArray(tenantData.content)) {
        const allTenants = tenantData.content;
  
        // Store the full list of tenants
        setAllTenants(allTenants);
  
        // Apply initial filtering if the role is TenantAdmin
        applyTenantFiltering(allTenants);
      } else {
        console.error("Invalid tenant data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching tenant data:", error);
    }
  };
  const applyTenantFiltering = (tenantsList) => {
    // Get tenants where the user already has "TenantAdmin" role
    const tenantAdminTenants = userDetails.userGroupList
      .filter((group) => group.group === "TenantAdmin")
      .map((group) => group.tenant);
  
    // Get tenants where the user has any other role (ProjectAdmin or Reviewer)
    const otherRoleTenants = userDetails.userGroupList
      .filter((group) => ["ProjectAdmin", "Reviewer"].includes(group.group))
      .map((group) => group.tenant);
  
    // Apply filtering based on the selected role
    const filtered = formData.role?.value === "TenantAdmin"
      ? tenantsList.filter(
          (tenant) => 
            !tenantAdminTenants.includes(tenant.name) && // Exclude tenants where the user is already a TenantAdmin
            !otherRoleTenants.includes(tenant.name)     // Exclude tenants with other roles
        )
      : tenantsList.filter(
          (tenant) => 
            !tenantAdminTenants.includes(tenant.name)   // Exclude tenants where the user is already a TenantAdmin
        );
  
    setFilteredTenants(filtered); // Update the filtered tenants
  };
    
  
    
  useEffect(() => {
    fetchTenants();
  }, []);

  // Fetch projects based on selected tenant
  const fetchProjects = async (tenantId) => {
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }

      const projectResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: 10000, // Fetch a large number of projects
            search: "",
            status: "ACTIVE",
            sortColumn: "name",
            sortOrder: "DESC",
            tenantId: tenantId, // Pass the selected tenantId to filter projects
          },
        }
      );

      const projectData = projectResponse.data;

      if (Array.isArray(projectData.content)) {
        setAllProjects(projectData.content);
        // Filter out projects where the user already has a role
        let userProjectNames = [];
        userProjectNames = userDetails.userGroupList.map(
          (group) => group.project
        );

        const availableProjects = projectData.content.filter(
          (project) => !userProjectNames.includes(project.name)
        );
        setProjects(availableProjects);
        setFilteredProjects(availableProjects); // Update filtered projects
      } else {
        console.error("Invalid project data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  // Handle Role Change
  const handleRoleChange = (selectedOption) => {
    let newFormData = {
      ...formData,
      role: selectedOption || "",
      tenant: [],
      project: [],
    };
    setFormData(newFormData);
    setFieldErrors({ ...fieldErrors, role: "" });

  };
  
  useEffect(() => {
    applyTenantFiltering(allTenants);
  }, [formData.role, userDetails, allTenants]); // Add dependencies
  
  // Handle Tenant Change and Fetch Projects Based on Tenant
  const handleTenantChange = (selectedOption) => {
    const selectedTenant = selectedOption ? selectedOption.value : null;
    setFormData({
      ...formData,
      tenant: selectedTenant ? [selectedTenant] : [],
      project: [], // Clear project when changing tenant
    });

    // Fetch projects for the selected tenant
    if (selectedTenant && userDetails) {
      fetchProjects(selectedTenant);
    } else {
      setFilteredProjects([]); // Clear the filtered projects if no tenant is selected
    }
  };

  // Handle Save Click
  const handleSaveClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errors = {};
    if (!formData.role) {
      setFieldErrors({ ...fieldErrors, role: "Role is required" });
      return;
    }
    if (
      formData.role &&
      ["Reviewer", "ProjectAdmin"].includes(formData.role.value) &&
      formData.project.length === 0
    ) {
      errors.project = "Project is required";
    }
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    try {
      const token = await getCurrentToken();
      if (!token) {
        console.error("No token found");
        return;
      }

      const groupData = {
        userId: user.id,
        status: "ACTIVE",
        tenantId: formData.tenant[0],
        projectId: formData.project[0],
      };

      if (formData.role && formData.role.value === "SuperAdmin") {
        groupData.groupId = 1;
      } else if (formData.role && formData.role.value === "Reviewer") {
        groupData.groupId = 2;
      } else if (formData.role && formData.role.value === "ProjectAdmin") {
        groupData.groupId = 3;
      } else if (formData.role && formData.role.value === "TenantAdmin") {
        groupData.groupId = 4;
      } else if (formData.role && formData.role.value === "Developer") {
        groupData.groupId = 5;
      }

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        [groupData],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Fetch updated user details
      fetchUserDetails();
      fetchProjects();
      triggerRefresh();
      refreshGroup();

      // Clear form data
      setFormData({ role: null, project: [], tenant: [] });
      setShowForm(false);
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };
  const handleDeleteGroup = async (group) => {
    try {
      const token = await getCurrentToken();
      const isSingleGroup = userDetails.userGroupList.length === 1;

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        [
          {
            userId: user.id,
            tenantId: group.tenantId,
            projectId: group.projectId,
            groupId: group.groupId,
            userGroupId: group.userGroupId,
            status: "INACTIVE",
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // If it's the only group, deactivate the user
      if (isSingleGroup) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}user/status`,
          {
            id: user.id,
            status: "INACTIVE",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("User deactivated successfully.");
      }
      // Fetch updated user details and projects to reflect changes
      const updatedDetails = await fetchUserDetails();
      setUserDetails(updatedDetails);

      await fetchProjects();

      // Refresh UI
      triggerRefresh();

      // Reset form and hide
      setFormData({ role: null, project: [], tenant: [tenantId] });
      setShowForm(false);
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };
  // Function to handle showing the popover
  const handleTogglePopover = (groupId) => {
    if (showPopover === groupId) {
      setShowPopover(null); // Close the popover if clicked again
    } else {
      setShowPopover(groupId); // Show the popover for this group
    }
  };

  const createPopover = (group) => {
    const isSingleGroup = userDetails.userGroupList.length === 1;

    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {isSingleGroup
            ? "Removing this role will deactivate the user. Are you sure you want to proceed?"
            : "Are you sure you want to delete this group?"}
          <div className="mt-2">
            <button
              onClick={() => {
                if (isSingleGroup) {
                  handleDeleteGroup(group, user.id); // Call deactivate if it's the last group
                } else {
                  handleDeleteGroup(group); // Remove the group normally
                }
                setShowPopover(null);
              }}
              className="btn btn-sm btn-danger me-2 px-3 py-0"
            >
              Yes
            </button>
            <button
              onClick={() => setShowPopover(null)}
              className="btn btn-sm btn-secondary px-3 py-0"
            >
              No
            </button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  const handleAddRoleClick = () => {
    setShowForm(true);
    // Fetch updated projects
    fetchUserDetails();
    fetchProjects();
    fetchTenants();
  };
  const projectsWithoutRoles = projects.filter(
    (project) =>
      !userDetails.userGroupList.some((group) => group.project === project.name)
  );

  const isTooltipNeeded = projectsWithoutRoles.length === 0;

  const noProjects = allProjects.length === 0;
  const isTenantAdmin = formData.role?.value === "TenantAdmin";
  return (
    <div>
      {!showForm ? (
        <div className="text-end">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="add-role-tooltip">
                Click to assign a role to a project.
              </Tooltip>
            }
            container={document.querySelector(".modal")}
          >
            <span className="d-inline-block">
              <button
                className="btn btn-primary btn-sm px-3"
                onClick={handleAddRoleClick}
              >
                <PlusCircle /> Add Role
              </button>
            </span>
          </OverlayTrigger>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col-md-4">
              <Select
                options={roleOptions}
                value={formData.role}
                onChange={handleRoleChange}
                name="roles"
                placeholder="Select Role"
              />
              {fieldErrors.role && (
                <div className="text-danger">{fieldErrors.role}</div>
              )}
            </div>

            {["TenantAdmin", "ProjectAdmin", "Reviewer"].includes(
              formData.role?.value
            ) && (
              <div className="col-md-4">
                <Select
                  options={filteredTenants.map((tenant) => ({
                    value: tenant.id,
                    label: tenant.name,
                  }))}
                  value={
                    formData.tenant.length > 0
                      ? {
                          value: formData.tenant[0],
                          label: filteredTenants.find(
                            (tenant) => tenant.id === formData.tenant[0]
                          )?.name,
                        }
                      : null
                  }
                  onChange={handleTenantChange}
                  name="tenant"
                  placeholder="Select Tenant"
                />
              </div>
            )}

            {["ProjectAdmin", "Reviewer"].includes(formData.role?.value) &&
              formData.tenant.length > 0 && (
                <div className="col-md-4">
                  <Select
                    options={filteredProjects.map((project) => ({
                      value: project.id,
                      label: project.name,
                    }))}
                    value={
                      formData.project.length > 0
                        ? {
                            value: formData.project[0],
                            label: filteredProjects.find(
                              (project) => project.id === formData.project[0]
                            )?.name,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      let newFormData = {
                        ...formData,
                        project: selectedOption ? [selectedOption.value] : [],
                      };
                      setFormData(newFormData);
                    }}
                    name="projects"
                    placeholder="Select Project"
                  />
                  {fieldErrors.project && (
                    <div className="text-danger">{fieldErrors.project}</div>
                  )}
                </div>
              )}
          </div>
          <div className="text-end">
            <button
              className="btn btn-secondary btn-sm me-2"
              onClick={() => (
                setShowForm(false),
                (formData.role = ""),
                (formData.project = []),
                (formData.tenant = [])
              )}
            >
              Cancel
            </button>
            {!isTenantAdmin && (
              <>
                {isTooltipNeeded || noProjects ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="add-role-tooltip">
                        {noProjects
                          ? "No projects available to assign roles."
                          : "Roles have been assigned to all projects in this tenant."}
                      </Tooltip>
                    }
                    container={document.querySelector(".modal")}
                  >
                    <span className="d-inline-block">
                      <button
                        className="btn btn-primary btn-sm"
                        disabled={true} // Ensure disabled for both cases
                        style={{ pointerEvents: "none" }} // Enables tooltip on disabled button
                      >
                        Add
                      </button>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSaveClick}
                    disabled={isTooltipNeeded}
                  >
                    Add
                  </button>
                )}
              </>
            )}
            {isTenantAdmin && (
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSaveClick}
              >
                Add
              </button>
            )}
          </div>
        </>
      )}
      <table className="table table-borderless table-striped">
        <thead>
          <tr>
            <th>Role</th>
            <th>Tenant</th>
            <th>Project</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userDetails.userGroupList.map((group) => (
            <tr key={group.userGroupId}>
              <td>{group.group}</td>
              <td>{group.tenant}</td>
              <td>{group.project}</td>
              <td>
                <button
                  ref={(el) => (buttonRefs.current[group.userGroupId] = el)}
                  onClick={() => handleTogglePopover(group.userGroupId)}
                  type="button"
                  className="border-0 btn-round btn-danger"
                >
                  <Trash3 size={14} />
                </button>
                <Overlay
                  show={showPopover === group.userGroupId}
                  target={buttonRefs.current[group.userGroupId]}
                  placement="top"
                  rootClose
                  onHide={() => setShowPopover(null)}
                >
                  {createPopover(group)}
                </Overlay>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddGroupAdmin;
